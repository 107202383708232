<template>
  <div>
    <h1 class="card__heading title">Dummy profielen</h1>

    <!-- Dialogs -->
    <!-- Photo preview -->
    <el-dialog
      v-model="previewImage.show"
      @close="handleClosePreviewImageDialog"
    >
      <el-image
        :src="`${$store.getters.getConstant('API_URI')}/${previewImage.src}`"
        fit="contain"
        style="width: 100%; height: 70vh"
      >
        <template #placeholder>
          <i class="el-icon-loading" />
        </template>
      </el-image>
    </el-dialog>

    <!-- Add new dummy profiles -->
    <add-dummy-dialog
      v-model="addDummyDialog.open"
      @newDummy="handleNewDummy"
    />

    <el-button
      icon="el-icon-plus"
      circle
      type="primary"
      size="small"
      @click="addDummyDialog.open = true"
    />

    <!-- Dummy table -->
    <el-table :data="paginatedData" empty-text="Geen stemrondes gevonden">
      <!-- No content slot -->
      <template #empty>
        <el-button @click="addDummyDialog.open = true"
          >Voeg eerste dummy profiel toe</el-button
        >
      </template>

      <!-- Indicators -->
      <el-table-column min-width="60px">
        <template #default="{ row }">
          <i
            v-if="row.karaoke"
            class="el-icon-mic"
            style="margin-right: 5px; vertical-align: -2px"
          />
          <i
            v-if="row.image_url"
            class="el-icon-camera-solid"
            style="margin-right: 5px; vertical-align: -2px"
          />
        </template>
      </el-table-column>

      <!-- Title, artist, requester data -->
      <el-table-column prop="song" label="Titel" />
      <el-table-column prop="artist" label="Artiest" />
      <el-table-column label="Naam" min-width="100px">
        <template #default="{ row }">
          <p>
            {{ row.requester_firstname }}
            {{ row.requester_lastname }}
          </p>
        </template>
      </el-table-column>

      <!-- Action buttons -->
      <el-table-column width="200px" align="right">
        <template #default="{ row, $index }">
          <el-button
            v-if="row.image_url"
            size="mini"
            type="info"
            @click="handleShowPreviewImage(row)"
          >
            <i class="el-icon-camera-solid" />
          </el-button>

          <el-button
            size="mini"
            type="success"
            @click="handleAddDummyRequest(row)"
          >
            <i class="el-icon-magic-stick" />
          </el-button>

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete($event, $index, row)"
          >
            <i class="el-icon-delete" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-wrapper">
      <!-- Page size select -->
      <el-select v-model="pageSize" style="width: 75px">
        <el-option
          v-for="size in pageSizes"
          :key="size"
          :value="size"
          :label="size"
        ></el-option>
      </el-select>

      <!-- Pagination buttons-->
      <!-- eslint-disable -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalTableData"
        :page-size="pageSize"
        v-model:current-page="currentPage"
      >
      </el-pagination>
      <!-- eslint-enable -->

      <!-- Sync button -->
      <div>
        <el-button
          circle
          icon="el-icon-refresh-left"
          type="warning"
          size="small"
          @click="fetchDummies"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDialog,
  ElImage,
  ElSelect,
  ElOption,
  ElPagination,
  ElMessageBox,
} from "element-plus";

import fetchMixins from "../mixins/ajax";
import generalTableMixins from "../mixins/tables";

import AddDummyDialog from "@/components/AddDummyDialog";

export default {
  components: {
    AddDummyDialog,
    ElTable,
    ElTableColumn,
    ElDialog,
    ElImage,
    ElSelect,
    ElPagination,
    ElOption,
  },

  mixins: [fetchMixins, generalTableMixins],

  data() {
    return {
      addDummyDialog: {
        open: false,
      },
      dummyData: [],
      previewImage: {
        show: false,
        src: "",
      },
    };
  },

  computed: {
    paginatedData() {
      return this.dummyData.slice(this.fromIndex, this.toIndex);
    },
    totalTableData() {
      return this.dummyData.length;
    },
  },

  methods: {
    async fetchDummies() {
      const response = await this.fetchData(null, "dummy", "GET");

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Er is een fout opgetreden bij het laden van de dummy profielen. Probeer het later nog eens."
        );
        return;
      }

      this.dummyData = response.data;
    },

    handleClosePreviewImageDialog() {
      this.previewImage.src = "";
    },

    async handleDelete(ev, _index, row) {
      ev.stopPropagation();

      const confirmed = await ElMessageBox.confirm(
        "Weg is weg... Zo van, echt weg... Weet je het helemaal zeker?",
        "Even bevestigen",
        {
          confirmButtonText: "Verwijderen",
          cancelButtonClass: "Annuleren",
          type: "warning",
        }
      )
        .then(() => true)
        .catch(() => false);

      if (!confirmed) {
        return;
      }

      const response = await this.fetchData(null, `dummy/${row._id}`, "DELETE");

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Het dummy profiel kon niet worden verwijderd. Probeer het later nog eens."
        );
        return;
      }

      // // Remove item form table
      this.removeDummyFromTable(row._id);
    },

    handleNewDummy(dummy) {
      this.dummyData.push(dummy);
    },

    async handleAddDummyRequest(dummy) {
      const id = dummy._id;
      if (!id) {
        return this.$message(
          "Er is iets fout gegaan. Probeer het later nog eens."
        );
      }
      const response = await this.fetchData(null, "request/dummy", "POST", {
        id,
      });

      if (response.statusCode !== 200) {
        this.$message(`Er is iets fout gegaan. ${response.data.error}`);
        return;
      }

      if (dummy.song && dummy.artist)
        this.$message(
          `${dummy.song} (${dummy.artist}) is toegevoegd aan de lijst met verzoekjes!`
        );
    },

    handleShowPreviewImage(row) {
      this.previewImage.src = row.image_url;
      this.previewImage.show = true;
    },

    removeDummyFromTable(id) {
      const indexToRemove = this.dummyData.findIndex(
        (request) => request._id === id
      );

      if (indexToRemove > -1) {
        this.dummyData.splice(indexToRemove, 1);
      }
    },
  },

  mounted() {
    this.fetchDummies();
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  margin-bottom: 2rem;
}

.defaultStatus {
  width: 1rem;
  height: 1rem;
  background-color: rgba(10, 10, 10, 0.65);
  border-radius: 50%;

  &--active {
    background-color: #67c23a;
  }
}
</style>
