<template>
  <el-card class="card--small">
    <p v-if="activeVote === null" class="notActive">
      Er is nu geen stemronde actief.
    </p>
    <div v-else>
      <el-row align="middle">
        <el-col :span="4">
          <el-button
            icon="el-icon-switch-button"
            type="primary"
            size="mini"
            circle
            @click="stopVote"
          ></el-button>
        </el-col>
        <el-col :span="16" style="display: flex; align-items: center"
          ><p class="voteName">{{ activeVote.name }}</p></el-col
        >
        <el-col :span="4" style="display: flex; align-items: center">
          <VoteClock :vote="activeVote" :show="!!activeVote" @end="stopVote" />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VoteClock from "./VoteClock";
export default {
  components: {
    VoteClock,
  },
  props: {
    activeVote: {
      type: Object,
    },
  },
  emits: ["stopVote"],
  methods: {
    stopVote() {
      this.$emit("stopVote");
    },
  },
  watch: {
    activeVote() {
      if (this.activeVote === null) {
        return this.$message({
          message: "De stemronde is gestopt.",
          type: "warning",
          "show-close": true,
          duration: 0,
        });
      }
      this.$message({
        message: `Stemronde <strong>${this.activeVote.name}</strong> is actief.`,
        type: "success",
        dangerouslyUseHTMLString: true,
      });
    },
  },
};
</script>

<style lang="scss">
.notActive {
  color: $color-light-gray;
  font-style: italic;
}

.voteName {
  color: $color-primary;
  font-weight: bold;
}
</style>
