<template>
  <div class="section">
    <h3 class="card__description">Download ingestuurde foto's</h3>
    <el-row>
      <el-col>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          :disabledDate="disableDates"
          start-placeholder="Begindatum"
          end-placeholder="Einddatum"
          :disabled="loading"
          :default-time="[defaultTime, defaultTime]"
        ></el-date-picker>
        <el-button
          type="success"
          style="margin-left: 2rem"
          @click="handleDownload"
          :loading="loading"
          >Download</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import fetchMixins from "../mixins/ajax";

import { ElDatePicker } from "element-plus";
export default {
  components: {
    ElDatePicker,
  },
  mixins: [fetchMixins],
  data() {
    return {
      dateRange: null,
      loading: false,
      defaultTime: new Date(2024, 1, 1, 5, 0),
    };
  },

  methods: {
    disableDates(date) {
      // Create boundary date
      const dateBound = new Date();
      dateBound.setDate(
        dateBound.getDate() -
          (process.env.VUE_APP_IMAGE_DOWNLOAD_HISTORY_DAYS ?? 3)
      );
      dateBound.setHours(0);
      dateBound.setMinutes(0);
      dateBound.setSeconds(0);
      dateBound.setMilliseconds(0);

      // Return true (disable date) when date is in the future, or date is more than three days ago
      return new Date() - new Date(date) < 0 || new Date(date) < dateBound;
    },
    async handleDownload() {
      if (this.dateRange === null) {
        return this.$message({
          message: "Geef het datumbereik op.",
          type: "warning",
        });
      }

      const from = this.dateRange[0];
      const to = this.dateRange[1];
      // Set to the next day, 00:00h
      to.setDate(to.getDate() + 1);

      const URI = `${this.$store.getters.getConstant(
        "API_URI"
      )}/request/images?from=${from}&to=${to}&nodummies=true`;

      this.loading = true;
      const response = await fetch(URI, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$store.getters.userToken}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "images.zip";
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
          return true;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          this.dateRange = null;
          this.loading = false;
        });

      if (!response) {
        this.$message("Er is een fout opgetreden. Probeer het later nog eens.");
        return;
      }
    },
  },
};
</script>
