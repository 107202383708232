<template>
  <div style="max-width: 20rem">
    <el-dialog
      :modelValue="modelValue"
      @close="$emit('update:modelValue', false)"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="Dummy profiel toevoegen"
      custom-class="card--small"
    >
      <el-form
        :model="form"
        label-position="top"
        :rules="formRules"
        size="small"
        :show-message="true"
        :hide-required-asterisk="true"
        :status-icon="false"
        ref="form"
      >
        <el-form-item label="Titel" prop="title">
          <el-input
            v-model="form.title"
            placeholder="Vul de titel van het nummer in"
            autofocus
            maxlength="24"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="Artiest" prop="artist">
          <el-input
            v-model="form.artist"
            placeholder="Vul de naam van de artiest in"
            maxlength="24"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="Voornaam aanvrager" prop="firstName">
          <el-input
            v-model="form.firstName"
            placeholder="Vul de voornaam in"
            maxlength="12"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="Achternaam aanvrager"
          prop="lastName"
          style="margin-bottom: 4rem"
        >
          <el-input
            v-model="form.lastName"
            placeholder="Vul de achternaam in"
            maxlength="12"
            show-word-limit
          />
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="form.karaoke" :border="true"
            >Karaoke</el-checkbox
          >
        </el-form-item>

        <el-form-item label="Foto" style="margin-bottom: 0">
          <el-upload
            ref="upload"
            action=""
            class="image-uploader"
            :on-change="handleChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="image" />
            <i v-else class="el-icon-picture image-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancel" :disabled="isSubmitting"
          >Annuleren</el-button
        >
        <el-button
          type="primary"
          @click="save"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          >Opslaan</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  // ElTable,
  // ElTableColumn,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElUpload,
  ElCheckbox,
  // ElImage,
  // ElSelect,
  // ElOption,
  // ElPagination,
  // ElBadge,
  // ElTag,
  // ElMessageBox
} from "element-plus";

import fetchMixins from "../mixins/ajax";

export default {
  components: {
    // ElTable,
    // ElTableColumn,
    ElDialog,
    ElForm,
    ElFormItem,
    ElUpload,
    ElInput,
    ElCheckbox,
    // ElImage,
    // ElBadge,
    // ElTag,
    // ElSelect,
    // ElPagination,
    // ElOption,
  },

  mixins: [fetchMixins],

  props: {
    // open/close state of the dialog
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      form: {
        title: "",
        artist: "",
        firstName: "",
        lastName: "",
        karaoke: false,
        image: null,
      },
      formRules: {
        title: [
          {
            required: true,
            message: "Vul de titel van het nummer in",
            trigger: "change",
          },
          { max: 24, message: "Gebruik maximaal 24 tekens", trigger: "change" },
        ],
        artist: [
          {
            required: true,
            message: "Vul de naam van de artiest in",
            trigger: "change",
          },
          { max: 24, message: "Gebruik maximaal 24 tekens", trigger: "change" },
        ],
        firstName: [
          { required: true, message: "Vul je voornaam in", trigger: "change" },
          { max: 12, message: "Gebruik maximaal 12 tekens", trigger: "change" },
        ],
        lastName: [
          {
            required: true,
            message: "Vul je achternaam in",
            trigger: "change",
          },
          { max: 12, message: "Gebruik maximaal 12 tekens", trigger: "change" },
        ],
      },
      imageUrl: null,
      isSubmitting: false,
    };
  },

  computed: {},

  methods: {
    cancel() {
      this.form = {
        title: "",
        artist: "",
        firstName: "",
        lastName: "",
        karaoke: false,
        image: null,
      };
      this.imageUrl = null;
      this.$refs["form"].resetFields();

      this.$emit("update:modelValue", false);
    },
    handleChange(file) {
      // Validate file type and size
      const isImage = file.raw.type.includes("image/");
      const hasAcceptedSize = file.size / 1024 / 1024 < 6;

      if (!isImage || !hasAcceptedSize) {
        this.$message({
          message: "Kies een foto van minder dan 6 MB",
          duration: 5000,
          customClass: "",
          showClose: true,
        });
        return;
      }

      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.image = file.raw;
      this.$refs.upload.clearFiles();
    },

    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.isSubmitting = true;

          const payload = new FormData();
          payload.append("artist", this.form.artist);
          payload.append("song", this.form.title);
          payload.append("requester_firstname", this.form.firstName);
          payload.append("requester_lastname", this.form.lastName);
          payload.append("karaoke", this.form.karaoke);

          if (this.form.image !== null) {
            payload.append("image", this.form.image);
          }

          const response = await this.fetchData(
            null,
            "dummy/create",
            "POST",
            null,
            payload
          );

          if (response.statusCode !== 200) {
            this.$message(`Er is iets fout gegaan. ${response.error.message}`);
            return;
          }

          const newDummy = response.data;
          this.$emit("newDummy", newDummy);
          this.isSubmitting = false;

          this.cancel();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.image-uploader ::v-deep .el-upload:hover {
  border-color: $color-primary;
}
.image-uploader-icon {
  font-size: 6.5rem;
  color: #8c939d;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
}
.image {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  display: block;
  background-color: #303133;
}

::v-deep .el-dialog__body {
  padding-bottom: 0;
}
</style>
