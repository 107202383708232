<template>
  <div>
    <h1 class="card__heading title">Stemrondes</h1>
    <!-- Widget for monitoring active Vote -->
    <vote-status-widget
      :activeVote="activeVote"
      @stopVote="handleStopVote"
      style="margin: 0 0 1rem auto"
    ></vote-status-widget>

    <!-- Create new Vote button -->
    <el-button
      icon="el-icon-plus"
      circle
      type="primary"
      size="small"
      @click="toggleDialog('createVote')"
    ></el-button>

    <!-- Votes table -->
    <el-table
      :data="paginatedData"
      @row-click="handleRowClick"
      @expand-change="handleExpandChange"
      ref="table"
      empty-text="Geen stemrondes gevonden"
    >
      <!-- Expanded row -->
      <el-table-column type="expand" width="1px">
        <template #default="props">
          <el-row :gutter="20">
            <el-col
              :md="6"
              :sm="12"
              v-for="option in props.row.options"
              :key="option.id"
            >
              <el-card :body-style="{ padding: '0px' }">
                <el-image
                  v-if="option.image_url"
                  :src="`${$store.getters.getConstant('API_URI')}/${
                    option.image_url
                  }`"
                  style="height: 15rem"
                  fit="cover"
                >
                  <template #placeholder>
                    <div class="image-slot">
                      <i class="el-icon-loading"></i>
                    </div>
                  </template>
                  <template #error>
                    <div class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                      <p>Kan foto niet laden</p>
                    </div>
                  </template>
                </el-image>
                <div v-else class="option-image-default"></div>
                <div style="padding: 1rem">
                  <el-badge :value="getCount(props.row, option)"
                    ><el-tag>{{ option.name }}</el-tag></el-badge
                  >
                </div>
              </el-card>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <!-- Active status -->
      <el-table-column width="25px">
        <template #default="scope">
          <div
            :class="
              activeVote !== null && isActive(scope.row._id)
                ? 'activeStatus activeStatus--active'
                : 'activeStatus'
            "
          ></div>
        </template>
      </el-table-column>

      <!-- Vote name -->
      <el-table-column prop="name" label="Stemronde"></el-table-column>

      <!-- Action buttons -->
      <el-table-column width="160px">
        <template #default="scope">
          <el-button
            size="mini"
            type="info"
            @click="handleStartVote($event, scope.$index, scope.row)"
            :disabled="activeVote !== null"
            ><i class="el-icon-video-play"></i
          ></el-button>

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete($event, scope.$index, scope.row)"
            :disabled="isActive(scope.row._id)"
            ><i class="el-icon-delete"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <div class="pagination-wrapper">
      <!-- Page size select -->
      <el-select v-model="pageSize" style="width: 75px">
        <el-option
          v-for="size in pageSizes"
          :key="size"
          :value="size"
          :label="size"
        ></el-option>
      </el-select>

      <!-- Pagination -->
      <!-- eslint-disable -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalTableData"
        :page-size="pageSize"
        v-model:current-page="currentPage"
      >
      </el-pagination>
      <!-- eslint-enable -->

      <!-- Sync button -->
      <el-button
        circle
        icon="el-icon-refresh-left"
        type="warning"
        size="small"
        @click="fetchVotes"
      ></el-button>
    </div>

    <!-- Dialogs - CreateVote -->
    <el-dialog
      title="Maak een nieuwe stemronde"
      v-model="dialogs.createVote"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form
        :model="forms.createVote"
        label-position="top"
        :rules="formRules"
        :show-message="true"
        :hide-required-asterisk="true"
        :status-icon="true"
        ref="createVoteForm"
      >
        <el-form-item label="Naam" prop="name">
          <el-input
            v-model="forms.createVote.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <!-- The file uploads + option name inputs -->
          <el-col v-for="i in 4" :key="i" :lg="6" :sm="12">
            <el-form-item :label="`Optie ${i}`" style="margin-bottom: 0">
              <el-upload
                :ref="`upload${i}`"
                action=""
                class="image-uploader"
                :on-change="
                  (file, fileList) => {
                    handleChange(file, fileList, `${i}`);
                  }
                "
                :show-file-list="false"
                :auto-upload="false"
              >
                <img
                  v-if="forms.createVote.options[i - 1].image"
                  :src="forms.createVote.options[i - 1].image"
                  class="image"
                />
                <i v-else class="el-icon-picture image-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item :prop="`options[${i - 1}].name`">
              <el-input
                placeholder="Titel"
                v-model="forms.createVote.options[i - 1].name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeCreateVoteForm">Annuleren</el-button>
          <el-button type="primary" @click="submitCreateVoteForm()"
            >Opslaan</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- Dialogs - StartVote -->
    <el-dialog
      v-model="dialogs.startVote"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #title>
        <p>
          Activeer stemronde
          <span class="text-primary">{{
            selectedVote ? selectedVote.name : ""
          }}</span>
        </p>
      </template>
      <el-form
        :model="forms.startVote"
        hide-required-asterisk
        ref="startVoteForm"
        label-position="top"
      >
        <el-form-item label="Tijdsduur" required>
          <el-time-select
            v-model="forms.startVote.duration"
            :clearable="false"
            start="00:05"
            step="00:05"
            end="00:30"
            placeholder="Selecteer de tijdsduur"
          ></el-time-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="toggleDialog('startVote')">Annuleren</el-button>
          <el-button
            type="primary"
            @click="toggleDialog('startVote'), submitStartVoteForm()"
            >Activeer</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElUpload,
  ElImage,
  ElSelect,
  ElOption,
  ElPagination,
  ElBadge,
  ElTag,
  ElTimeSelect,
  ElMessageBox,
} from "element-plus";

import generalTableMixins from "../mixins/tables";
import fetchMixins from "../mixins/ajax";
import VoteStatusWidget from "../components/VoteStatusWidget";
export default {
  components: {
    ElTable,
    ElTableColumn,
    ElDialog,
    ElForm,
    ElFormItem,
    ElUpload,
    ElInput,
    ElImage,
    ElBadge,
    ElTag,
    ElTimeSelect,
    VoteStatusWidget,
    ElSelect,
    ElPagination,
    ElOption,
  },
  mixins: [generalTableMixins, fetchMixins],
  inject: ["io"],
  data() {
    return {
      dialogs: {
        createVote: false,
        startVote: false,
      },
      forms: {
        createVote: {
          name: "",
          options: [
            {
              name: "",
              image: null,
            },
            {
              name: "",
              image: null,
            },
            {
              name: "",
              image: null,
            },
            {
              name: "",
              image: null,
            },
          ],
          images: [null, null, null, null],
        },
        startVote: {
          duration: "00:15",
        },
      },
      formRules: {
        name: [
          {
            required: true,
            message: "Dit is een verplicht veld",
            trigger: "blur",
          },
          { max: 25, message: "Gebruik maximaal 25 tekens", trigger: "blur" },
        ],
        "options[0].name": [
          {
            required: true,
            message: "Dit is een verplicht veld",
            trigger: "blur",
          },
          { max: 25, message: "Gebruik maximaal 25 tekens", trigger: "blur" },
        ],
        "options[1].name": [
          {
            required: true,
            message: "Dit is een verplicht veld",
            trigger: "blur",
          },
          { max: 25, message: "Gebruik maximaal 25 tekens", trigger: "blur" },
        ],
        "options[2].name": [
          {
            required: true,
            message: "Dit is een verplicht veld",
            trigger: "blur",
          },
          { max: 25, message: "Gebruik maximaal 25 tekens", trigger: "blur" },
        ],
        "options[3].name": [
          {
            required: true,
            message: "Dit is een verplicht veld",
            trigger: "blur",
          },
          { max: 25, message: "Gebruik maximaal 25 tekens", trigger: "blur" },
        ],
      },
      votesData: [],
      selectedVote: null,
    };
  },
  computed: {
    totalTableData() {
      return this.votesData.length;
    },
    paginatedData() {
      return this.votesData.slice(this.fromIndex, this.toIndex);
    },
    getCount() {
      return (row, option) =>
        this.activeVote && this.activeVote._id === row._id
          ? this.activeVote.options.find((op) => op._id === option._id).count
          : 0;
    },
    isActive() {
      return (id) => this.activeVote && this.activeVote._id === id;
    },
    activeVote: {
      get: function () {
        return this.$store.getters.activeVote;
      },
      set: function (newVal) {
        this.$store.commit("setActiveVote", newVal);
      },
    },
  },
  methods: {
    // Fetch all votes to populate the data table
    async fetchVotes() {
      const response = await this.fetchData(null, "vote", "GET", null, null);

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Er is een fout opgetreden bij het laden van de stemrondes. Probeer het later nog eens."
        );
        return;
      }

      this.votesData = response.data;
    },
    // Toggle the specified dialog
    toggleDialog(dialog) {
      this.dialogs[dialog] = !this.dialogs[dialog];
    },
    // Handle image uploads in createVote dialog
    handleChange(file, _fileList, optionNumber) {
      // Validate file type and size
      const isImage = file.raw.type.includes("image/");
      const hasAcceptedSize = file.size / 1024 / 1024 < 5;

      if (!isImage || !hasAcceptedSize) {
        this.$message({
          message: "Kies een foto van maximaal 5 MB",
          duration: 5000,
          customClass: "",
          showClose: true,
        });
        return;
      }

      this.forms.createVote.options[optionNumber - 1].image =
        URL.createObjectURL(file.raw);
      this.forms.createVote.images[optionNumber - 1] = file.raw;
      this.$refs[`upload${optionNumber}`].clearFiles();
    },
    // Handle click on delete button
    async handleDelete(ev, index, row) {
      ev.stopPropagation();

      const confirmed = await ElMessageBox.confirm(
        "Weet je zeker dat je deze geweldige stemronde wilt verwijderen?",
        "Even bevestigen",
        {
          confirmButtonText: "Verwijderen",
          cancelButtonClass: "Annuleren",
          type: "warning",
        }
      )
        .then(() => true)
        .catch(() => false);

      if (!confirmed) {
        return;
      }

      const response = await this.fetchData(
        null,
        `vote/${row._id}`,
        "DELETE",
        null,
        null
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "De stemronde kon niet worden verwijderd. Probeer het later nog eens."
        );
        return;
      }

      // Remove item form table
      this.removeRequestFromTable(row._id);
    },
    // Submit the form for creating a new Vote
    async submitCreateVoteForm() {
      const valid = await this.$refs["createVoteForm"]
        .validate()
        .catch(() => false);

      if (valid) {
        const payload = new FormData();
        payload.append("name", this.forms.createVote.name);

        payload.append(
          "options[0][name]",
          this.forms.createVote.options[0]["name"]
        );
        payload.append(
          "options[1][name]",
          this.forms.createVote.options[1]["name"]
        );
        payload.append(
          "options[2][name]",
          this.forms.createVote.options[2]["name"]
        );
        payload.append(
          "options[3][name]",
          this.forms.createVote.options[3]["name"]
        );
        this.forms.createVote.images.forEach((image) => {
          payload.append("images", image);
        });

        const response = await this.fetchData(
          null,
          "vote/create",
          "POST",
          null,
          payload
        );

        const generalErrors = this.errorHandler(response);
        if (generalErrors) {
          return;
        }

        if (response.statusCode !== 200) {
          this.$message("Er is iets fout gegaan");
          return;
        }

        this.clearCreateVoteForm();

        this.fetchVotes();
        this.toggleDialog("createVote");
      }
    },
    closeCreateVoteForm() {
      this.clearCreateVoteForm();
      this.toggleDialog("createVote");
    },
    clearCreateVoteForm() {
      this.$refs["createVoteForm"].resetFields();
      this.forms.createVote.options.forEach((option) => {
        option.image = null;
      });
      this.forms.createVote.images = [null, null, null, null];
    },
    // Submit the form to start an existing Vote
    submitStartVoteForm() {
      this.$refs["startVoteForm"].validate(async (valid) => {
        if (valid) {
          // convert duration to seconds
          const duration =
            parseInt(this.forms.startVote.duration.split(":")[1]) * 60 * 1000;

          const response = await this.fetchData(
            null,
            `vote/start/${this.selectedVote._id}/${duration}`,
            "POST",
            null,
            null
          );

          const generalErrors = this.errorHandler(response);
          if (generalErrors) {
            return;
          }

          if (response.statusCode !== 200) {
            this.$message(
              "Kan de stemronde niet activeren. Probeer het later nog eens."
            );
            return;
          }

          // this.activeVote = response.data;

          // this.fetchVotes();
        }
      });
    },
    // Handle click on the start Vote button
    handleStartVote(ev, index, row) {
      ev.stopPropagation();
      this.selectedVote = row;
      this.toggleDialog("startVote");
    },
    // Handle click on the stop Vote button
    async handleStopVote() {
      const response = await this.fetchData(
        null,
        "vote/stop",
        "POST",
        null,
        null
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Er is een fout opgetreden bij het stoppen van de actieve stemronde. Probeer het later nog eens."
        );
        return;
      }

      this.activeVote = null;
    },
    // Fetch the currently active Vote
    async fetchActiveVote() {
      const response = await this.fetchData(
        null,
        "vote/active",
        "GET",
        null,
        null
      );

      const generalErrors = this.errorHandler(response);
      if (generalErrors) {
        return;
      }

      if (response.statusCode === 404) {
        this.activeVote = null;
        localStorage.removeItem("voteScreen");
        return;
      }

      if (response.statusCode !== 200) {
        this.$message(
          "Er is een fout opgetreden bij het laden van de actieve stemronde. Probeer het later nog eens."
        );
        return;
      }

      this.$store.commit("setActiveVote", response.data);
      // Handle local storage
      const storedVoteScreen = localStorage.getItem("voteScreen");
      if (storedVoteScreen === response.data._id) {
        this.$store.commit("setActiveScreen", "vote");
      } else if (storedVoteScreen) {
        localStorage.setItem("voteScreen", response.data._id);
      }
    },
    // Increment the counter for the Vote option with the specified id
    incrementVoteCounter(id) {
      this.activeVote.options.find((option) => option._id === id).count++;
    },
    // Remove request from data table by its ID
    removeRequestFromTable(id) {
      const indexToRemove = this.votesData.findIndex(
        (request) => request._id === id
      );

      if (indexToRemove > -1) {
        this.votesData.splice(indexToRemove, 1);
      }
    },
  },
  mounted() {
    this.fetchVotes();
    this.fetchActiveVote();

    this.io.on("vote-start", (payload) => {
      this.activeVote = payload.vote;
    });
    this.io.on("vote-stop", () => {
      this.activeVote = null;
    });
    this.io.on("vote-add", (payload) => {
      this.incrementVoteCounter(payload.id);
    });
  },
  unmounted() {
    this.io.off();
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  margin-bottom: 2rem;
}

.image-uploader {
  aspect-ratio: 1 / 1;
}

.image-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.image-uploader ::v-deep .el-upload:hover {
  border-color: $color-primary;
}
.image-uploader-icon {
  font-size: 5.5rem !important;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 12rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.option-image-default {
  height: 15rem;
}

::v-deep .el-table__expand-icon {
  display: none;
}

::v-deep .el-table__row {
  cursor: pointer;
  color: $color-black;
  font-size: 18px;
}

::v-deep .el-table__expanded-cell div {
  & div:nth-of-type(1) div div div.option-image-default {
    background-color: $color-primary;
  }
  & div:nth-of-type(2) div div div.option-image-default {
    background-color: #e6a23c;
  }
  & div:nth-of-type(3) div div div.option-image-default {
    background-color: #909399;
  }
  & div:nth-of-type(4) div div div.option-image-default {
    background-color: #000;
  }
}

.activeStatus {
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-radius: 50%;

  &--active {
    background-color: #67c23a;
  }
}
</style>
