<template>
  <el-button type="success" @click="handleClick">Exporteren</el-button>
</template>

<script>
import xlsx from "json-as-xlsx";
import fetchMixins from "../mixins/ajax";

export default {
  mixins: [fetchMixins],
  props: {
    dateRange: {
      type: Array,
    },
  },
  methods: {
    async handleClick() {
      // Fetch all requests in the specified date range

      if (
        this.dateRange === null ||
        (Array.isArray(this.dateRange) && this.dateRange.length === 0)
      ) {
        return this.$message({
          message: "Geef het datumbereik op.",
          type: "warning",
        });
      }
      const queryStringObj = { nodummies: true };

      queryStringObj.from = this.dateRange[0];

      const to = this.dateRange[1];
      // Set to the next day, 00:00h
      to.setDate(to.getDate() + 1);
      queryStringObj.to = to;

      const response = await this.fetchData(
        null,
        "request",
        "GET",
        queryStringObj,
        null
      );

      this.$emit("done");

      if (response.statusCode !== 200) {
        this.$message("Er is een fout opgetreden. Probeer het later nog eens.");
        return;
      }

      const content = response.data;

      // Define colums
      const columns = [
        { label: "Titel", value: "song" },
        { label: "Artiest", value: "artist" },
        {
          label: "Aangevraagd door",
          value: (row) => {
            return `${row.requester_firstname} ${row.requester_lastname}`;
          },
        },
        {
          label: "Karaoke",
          value: (row) => {
            return row.karaoke ? "Ja" : "Nee";
          },
        },
        {
          label: "Datum",
          value: (row) => {
            return new Date(row["createdAt"]);
          },
        },
      ];

      // Define settings
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
      const settings = {
        sheetName: "Verzoekjes",
        fileName: `Blizzert ${formattedDate}`,
        extraLength: 3,
      };

      // Handle download
      xlsx(columns, content, settings);
    },
  },
};
</script>
