<template>
  <div>
    <el-tabs
      v-model="activeTab"
      type="border-card"
      style="max-width: 140rem; margin: 0 auto"
    >
      <el-tab-pane label="Verzoekjes" name="request">
        <requests-table></requests-table>
      </el-tab-pane>
      <el-tab-pane label="Stemrondes" name="vote">
        <votes-table></votes-table>
      </el-tab-pane>
      <el-tab-pane label="Exporteren" name="export">
        <h1 class="card__heading text-center">Exporteren</h1>
        <export-section></export-section>
        <download-section></download-section>
      </el-tab-pane>
      <el-tab-pane label="Dummy profielen" name="dummy">
        <dummy-table />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import { ElTabs, ElTabPane } from "element-plus";
import RequestsTable from "../components/RequestsTable";
import VotesTable from "../components/VotesTable";
import DummyTable from "../components/DummyTable";

import ExportSection from "../components/ExportSection";
import DownloadSection from "../components/DownloadSection";

export default {
  name: "AdminDashboard",
  components: {
    RequestsTable,
    ExportSection,
    DownloadSection,
    ElTabPane,
    ElTabs,
    VotesTable,
    DummyTable,
  },
  provide() {
    return {
      io: this.io,
    };
  },
  computed: {
    activeScreen() {
      return this.$store.getters.activeScreen;
    },
    activeTab: {
      get() {
        return this.$store.getters["dashboardActiveTab"];
      },
      set(value) {
        this.$store.commit("setDashboardActiveTab", value);
      },
    },
    userUpdatedScreen() {
      return this.$store.getters.userUpdatedScreenStatus;
    },
  },
  methods: {
    changeScreen(screen) {
      this.io.emit("screen-change", screen);
    },
  },
  beforeCreate() {
    const ROOT = this.$store.getters.getConstant("SOCKET_IO_ROOT");
    const PATH = this.$store.getters.getConstant("SOCKET_IO_PATH");
    this.io = io(ROOT, {
      path: PATH,
    });
    this.io.on("determineOffset", () => {}); // A first event is needed to make recovery work
  },
  watch: {
    // Sync ScreenPortal with activeScreen when user updates screen status
    userUpdatedScreen(newVal) {
      if (newVal) {
        this.changeScreen(this.activeScreen);
        this.$store.commit("resetUserUpdateStatus");
      }
    },
  },
  mounted() {
    // Emit event so ScreenPortal page can respond
    setTimeout(() => {
      this.io.emit("dashboard-reloaded");
    }, 1000);

    // Sync activeScreen with ScreenPortal when ScreenPortal page is reloaded
    this.io.on("screen-change", (value) => {
      if (value === "screen-portal-reloaded") {
        this.$store.commit("setActiveScreen", "request");
      } else {
        this.$store.commit("setActiveScreen", value);
      }
    });
  },
  unmounted() {
    this.io.off();
  },
};
</script>

<style lang="scss" scoped></style>
