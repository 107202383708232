<template>
  <div class="section">
    <h3 class="card__description">
      Exporteer de verzoekjes naar een excel-bestand
    </h3>
    <el-row>
      <el-col>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          :disabledDate="disableDates"
          start-placeholder="Begindatum"
          end-placeholder="Einddatum"
          :default-value="[lastMonth, new Date()]"
          :default-time="[defaultTime, defaultTime]"
        ></el-date-picker>

        <export-button
          style="margin-left: 2rem"
          :dateRange="dateRange"
          @done="dateRange = []"
        ></export-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ExportButton from "./ExportButton";
import { ElDatePicker } from "element-plus";
export default {
  components: {
    ExportButton,
    ElDatePicker,
  },
  data() {
    return {
      dateRange: [],
      defaultTime: new Date(2024, 1, 1, 5, 0),
    };
  },
  computed: {
    lastMonth() {
      const x = new Date();
      x.setDate(1);
      x.setMonth(x.getMonth() - 1);

      return x;
    },
  },
  methods: {
    disableDates(date) {
      return new Date() - new Date(date) < 0;
    },
  },
};
</script>
